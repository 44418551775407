import { OrganizationAnalyticsProgramRoundDetails } from '../components/Organization/Details';

export function OrganizationAnalyticsProgramRoundDetailsRoute() {
  return <OrganizationAnalyticsProgramRoundDetails />;
}

export const Component = OrganizationAnalyticsProgramRoundDetailsRoute;

export function clientLoader() {
  return null;
}
